import './App.css';
import ImageGenerator from './Components/ImageGenerator/ImageGenerator';

function App() {
  return (
      <div>
        <ImageGenerator/>
      </div>
  );
}

export default App;
